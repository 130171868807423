<template>
  <div class=''>
    <!-- 图的位置 -->
    <div class="bg-f5">
      <img :src="bannerImg" class="w-max h-max" style="object-fit: cover;">
    </div>
    <!-- //图的位置 -->
    <div class="container">
      <div class="fs46 fw-600 text-center mt60 mb40">公司简介</div>
      <div class="color9D fs18 lh45 pl200 pr200">
        <div style="text-indent: 2em;">
          重庆铭达鸿智生物科技有限公司是一家致力于密闭环境空气管理产品研发与生产的高新技术企业。基于自主研发生产消毒、净化等空气管理产品（物理法与化学法）的广泛应用，通过AI及大数据技术助力公共卫生消毒领域的供应链数字化转型，赋能行业参与者共同推进中国公共卫生数字化防疫消毒体系建设，为疾病预防科学研究提供环境及个人消毒效果评估提供数据化支撑，辅助政府行政决策机构实现面向公共卫生区域从检测到消杀过程管理直至效果可视化呈现的一体化智能大数据平台。公司在方舱医院、隔离酒店、医疗机构等专业服务与销售渠道开拓上有丰富经验及资源。
        </div>
        <!-- <div style="text-indent: 2em;">
          公司以“科技造福人类、创新引领未来”的理念,积极实施“人才发展战略”,不断吸纳行业人才,形成了质量部、生技部、运营部等众多部门的行业人才,均具备扎实的理论与丰富的行业经验基础。公司投入大量资源,不断加大研发力度,聘请多位国内外知名生物学家、专家担任公司顾问,且与诸多高校和中科院等研究机构的科研人员签订科研合作协议,在人员配备和知识技能储备上基础雄厚并时刻与前沿接轨,在产品研制、开发等方面发挥重要作用。
        </div>
        <div style="text-indent: 2em;">
          立足当下,放眼未来。重庆铭达鸿智生物科技有限公司将继续秉承“诚信、务实、开拓、创新”的精神,以科学的态度、严谨的作风,努力掌握自己的核心技术,解放国内生物医药基础和应用研究的桎梏,努力成为具有国际影响力的中国生物科技品牌
        </div> -->
      </div>

      <!-- 董事长致辞 -->
      <!--      <div class="h720 mt120 flex">
        <div>
          <div class="y_box"></div>
          <div class="img_box w455 h667">
            <img src="@/assets/images/aboutUs/chairman.png" class="w-max h-max">
          </div>
        </div>
        <div class="mt53">
          <div class="ml249 fs46 fw-600">董事长致词</div>
          <div>暂无</div>
        </div>
      </div> -->
      <!-- //董事长致辞 -->

      <!-- 公司使命 -->
      <div class="mt120">
        <div class="fs46 fw-600 text-center mb40">公司使命·愿景</div>
        <div class="fs18 color9D mb40 lh45 pl200 pr200" style="text-indent:2em">
          首先,用“科技”守护公共卫生与人民健康“科技”一词已成为国家竞争力的代表,同时也能突出我们的核心竞争力是研发,并且我们也将产品形态不断地迭代与创新。卫生领域更是靠近我们当下选择的大赛道机遇,我们首先服务于卫生需求,其次服务于健康需求；先有卫生的基础与高度,再有健康的细分深度与广度,依托于卫生与健康才能更好的用“科技”守护公共卫生与人民健康。通过技术迭代与数据挖掘,让公共卫生普惠每个人。技术迭代是意味着主动推动变化,而数据挖掘有两层含义：一个是挖掘空气管理数据,另一个是挖掘消费者数据,最终提供出让用户放心的解决方案。普惠的意义重大,首先公共卫生一定要先着手普及,而“惠”一词有实惠和惠及的浅层意义,让每个人都可以“买得起、买得到、用得起、用得上”,就像Ford汽车的愿景是“让平民拥有汽车（让汽车进入家庭）”。
        </div>
      </div>
      <!-- //公司使命 -->
    </div>
    <!-- 公司历程 -->
    <div class="mt120 bg-f5 pl200 pr200 relative lh45 h655 pt30">
      <el-tabs :tab-position="tabPosition" @tab-click="handleClick">
        <el-tab-pane :label="item.time" v-for="(item,i) in list" :key="i">
          <div class="pt133 licheng_box h-max">
            <div class="fs48 fw-600 mr161 ml161">{{item.time}}</div>
            <div class="fs32 ml281 mt60 lh45">
              {{item.content}}
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="fs48 fw-600 licheng_text" style="color: rgba(62, 58, 57, .3);" v-show="showNextTime">{{nextTime}}
      </div>
    </div>
    <!-- //公司历程 -->

    <div class="container mt120 pb120 lh45">
      <!-- 分支机构 -->
      <div class="flex">
        <img src="@/assets/images/aboutUs/map.png" class="w1000 h808 mr110">
        <div class="pt80 flex1">
          <div class="fs46 fw-600">分支机构</div>
          <div class="fs18 color9D mt40">
            重庆铭达鸿智生物科技有限公司向全国布局,北京总部作为行政中心,重庆分公司作为主要生产基地及研发基地,杭州研发中心作为铭智生科的AIoT实验室。至此,铭智生科拥有独立研发、生产、运营的多项能力。
          </div>
        </div>
      </div>
      <!-- //分支机构 -->
    </div>

  </div>
</template>

<script>
import bannerImgJs from '@/mixin/bannerImg'
export default {
  data () {
    return {
      bannerType: 6,
      tabPosition: 'top',
      nextTime: '',
      list: [{
        time: '2022年7月27日',
        content: '中国移动通信集团北京有限公司与重庆铭达鸿智生物科技有限公司正式签署战略协议；'
      }, {
        time: '2022年7月22日',
        content: '中国医药对外贸易有限公司与重庆铭达鸿智生物科技有限公司正式签署代理合作协议'
      }, {
        time: '2022年7月18日',
        content: '北京潞河医院与重庆铭达鸿智生物科技有限公司战略合作正式落地'
      }, {
        time: '2022年7月4日',
        content: '重庆铭达鸿智生物科技有限公司成功中标中国国能集团有限公司防疫消毒机器人消毒机项目；'
      }, {
        time: '2022年6月',
        content: '中国移动与重庆铭达鸿智生物科技有限公司共同研发的数字哨兵正式落地；'
      }, {
        time: '2022年5月',
        content: '重庆铭达鸿智生物科技有限公司生产的消毒设备过新冠检测,杀灭率高达99.99%.'
      }, {
        time: '2022年5月',
        content: '重庆铭达鸿智生物科技有限公司成功申请国家知识产权局“一种水离子发生装置”的发明专利权；'
      }, {
        time: '2022年4月22日',
        content: '重庆铭达鸿智生物科技有限公司携手广东圆心药业有限公司签署战略协议,标志着日记本开始全面进军医药电商领域；'
      }, {
        time: '2022年4月20日',
        content: '重庆铭达鸿智生物科技有限公司携手北京品乐汇品牌管理有限公司,正式携手拓展线上新零售业务版图；'
      }, {
        time: '2022年4月2日',
        content: '重庆铭达鸿智生物科技有限公司向杭州海外海百纳酒店集中医学观察点捐赠低温等离子消毒机34台,多媒体消毒机1台、消毒液15箱；'
      }, {
        time: '2022年3月',
        content: '重庆铭达鸿智生物科技有限公司防疫指挥舱大数据看板1.0正式上线'
      }, {
        time: '2022年2月',
        content: '重庆铭达鸿智生物科技有限公司为北京冬奥会、冬残奥会延庆赛区提供价值数十万元的防疫装备供奥运场馆使用'
      }, {
        time: '2021年11月',
        content: '由重庆铭达鸿智生物科技有限公司自主研发的一元性二氧化氯空气消毒液,环境消毒液成功研发并顺利获得消字号备案资格,同时申请了产品相关的知识产权；'
      }, {
        time: '2021年9月',
        content: '重庆铭达鸿智生物科技有限公司与首旅集团旗下全资子公司北京首采运通电子商务有限责任公司签署战略协议,相关消毒产品正式入驻首旅集采平台；'
      }, {
        time: '2021年7月',
        content: '由重庆铭达鸿智生物科技有限公司自主研发的碳酸型次氯酸消毒液大获成功,并申请了产品相关的知识产权；'
      }, {
        time: '2021年01年',
        content: '重庆铭达鸿智生物科技有限公司与国能物资南方有限公司（国能总部）签署战略协议,相关消毒产品正式入驻国能e购平台；'
      }, {
        time: '2021年01月',
        content: '河北省石家庄突发大规模新冠疫情,重庆铭达鸿智生物科技有限公司继武汉疫情捐赠后,又一次向石家庄市藁城区妇幼保健院及平山县红十字会捐赠500桶次氯酸水消毒液,用于医疗机构的环境消毒；'
      }, {
        time: '2020年08月',
        content: '重庆铭达鸿智生物科技有限公司获得中国室内（车内）环境净化治理服务施工等级评定证书（甲级）资质；'
      }, {
        time: '2020年07月',
        content: '重庆铭达鸿智生物科技有限公司获得中国室内环境净化与监测服务企业资质证书A级,以及中国有害生物防制服务企业资质等级证书A级的相关资质；'
      }, {
        time: '2020年01月',
        content: '新冠疫情突发,创始人带领员工身先士卒,共同抗疫,先后为武汉大学口腔医院、十堰东风医院、四川八一康复中心等相关医疗机构捐助消毒防护物资。'
      }, {
        time: '2019年10月',
        content: '重庆铭达鸿智生物科技有限公司成立；'
      },],
      move: 0,
      showNextTime: true
    };
  },
  mixins: [bannerImgJs],
  components: {},

  created () {
    this.$store.commit('editAboutRouterName', this.$route.meta.title)
    this.nextTime = this.list[1].time
  },

  mounted () { },

  methods: {
    handleClick (e) {
      e.index == 20 ? this.showNextTime = false : this.showNextTime = true
      if (e.index == this.list.length - 1) {
        this.nextTime = this.list[0].time
      } else {
        this.nextTime = this.list[e.index * 1 + 1].time
      }

    }
  }
}
</script>
<style lang='scss' scoped>
.y_box {
  margin-left: 178px;
  width: 318px;
  height: 160px;
  background: rgba(247, 181, 0, 0.24);
}

.img_box {
  z-index: 999;
  margin-top: -107px;
}

.licheng_box {
  width: 1200px;
}

.licheng_text {
  position: absolute;
  left: 500px;
  bottom: 10px;
}

::v-deep .el-tabs__item {
  height: 62px;
  font-size: 20px;
  color: #717985;
}

::v-deep .el-tabs__item.is-active {
  color: #f7b500;
}

::v-deep .el-tabs__active-bar {
  background-color: #f7b500 !important;
}

::v-deep .el-tabs__item:hover {
  color: #f7b500;
}

::v-deep .el-tabs__nav-next {
  font-size: 32px !important;
}

::v-deep .el-tabs__nav-prev {
  left: -8px;
  font-size: 32px !important;
}
</style>
